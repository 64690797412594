<script>
export default {
  name: 'Logo',
};
</script>

<template>

  <div class="w-16">

    <img alt="YPG logo" src="../assets/logo.png">

  </div>

</template>

<style scoped>

</style>
